<template>
  <div>
    <vs-sidebar
        position-right
        v-model="active"
        parent="body" default-index="55555551"
        class="items-no-padding">
        <!-- 
        hidden-background -->

        <div v-if="isLoadingProperty || isUpdatingProperty || isDeletingProperty" class="h-100">
            <div class="text-center flex-center h-100">
                <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Chargement...</span>
                </div>
                <br />
                Chargement des détails du collaborateur...
            </div>
        </div> 

        <div v-else class="h-full">
            

            <div class="sidebar-header py-1 px-2">
                <div>
                    <h3>{{property.firstName}} {{property.lastName}}</h3>
                    <small v-show="property.matricule">Matricule : {{property.matricule}}</small>
                </div>
                <feather-icon icon="XIcon" size="24" @click.stop="active =!active" class="close-icon cursor-pointer"></feather-icon>
            </div>

            <vs-divider class="m-0" />

            <div class="sidebar-action py-05 px-2">
              <div class="d-flex align-items-center">
                  <b-button
                    v-if="archived"
                    variant="warning"
                    class="btn-icon"
                    @click="restorePropertyLocal(property.id, property.firstName+' '+property.lastName)"
                  >
                      <feather-icon icon="ArrowDownIcon" /> Restaurer
                  </b-button>
                  <b-button
                    v-else
                    class="btn-icon"
                    variant="primary"
                    @click="editProperty(property.id)"
                    :disabled="archived"
                  >
                      <feather-icon icon="EditIcon" /> Modifier
                  </b-button>
              </div>
              <b-button-group>
                  <b-button
                  v-if="archived"
                    variant="outline-danger"
                    class="btn-icon"
                    @click="deletePropertyLocal(property.id, property.firstName+' '+property.lastName)"
                  >
                      <feather-icon icon="TrashIcon" />  Supprimer définitivement
                  </b-button>
                  <b-button
                    v-else
                    variant="outline-danger"
                    class="btn-icon"
                    @click="archivePropertyLocal(property.id, property.firstName+' '+property.lastName)"
                  >
                      <feather-icon icon="ArchiveIcon" /> 
                  </b-button>
              </b-button-group>
            </div>

            <vs-divider class="m-0" />

            <div class="sidebar-content">
                <div v-show="property.email || property.phoneNumber">
                    <h4 class="mb-1 text-primary"><feather-icon icon="PhoneCallIcon" /> Coordonnées</h4>
                    <ul class="listTab">
                        <li>
                            <span>Adresse e-mail</span>
                            <span>{{property.email}}</span>
                        </li>
                        <li>
                            <span>Téléphone</span>
                            <span>{{property.phoneNumber}}</span>
                        </li>
                    </ul>
                    <vs-divider /> 
                </div>
                <div v-show="property.address || property.zipCode || property.city || property.country">
                    <h4 class="mb-1 text-primary"><feather-icon icon="MapPinIcon" /> Adresse</h4>
                    <p class="paraphbox">
                        <span v-show="property.address">{{property.address}}<br></span>
                        <span v-show="property.addressComplement">{{property.addressComplement}}<br></span>
                        <span v-show="property.zipCode || property.city ">{{property.zipCode}} {{property.city}}<br></span>
                        <span v-show="property.country ">{{property.country}}</span>
                    </p>
                    <vs-divider /> 
                </div>
                <div v-show="property.job || property.contract || property.matricule || property.socialSecurityNumber">
                    <h4 class="mb-1 text-primary"><feather-icon icon="ClipboardIcon" /> Juridique</h4>
                    <ul class="listTab">
                        <li v-if="property.job">
                            <span>Métier</span>
                            <span>{{property.job.label}}</span>
                        </li>
                        <li v-if="property.contract">
                            <span>Contrat</span>
                            <span>{{property.contract.label}}</span>
                        </li>
                        <li v-show="property.matricule">
                            <span>Matricule</span>
                            <span>{{property.matricule}}</span>
                        </li>
                        <li v-show="property.socialSecurityNumber">
                            <span>N° Sécurité Sociale</span>
                            <span>{{property.socialSecurityNumber}}</span>
                        </li>
                    </ul>
                    <vs-divider /> 
                </div>
            </div>
        </div>
    </vs-sidebar>
  </div>
</template>


<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      archived:false,
      property:{}
    }
  },
  computed: {
    ...mapGetters([
      "isUpdatingProperty",
      "propertiesList",
      "isLoadingProperty",
      "isDeletingProperty",
      "isOpenSidebarProperty",
    ]),
    active: {
      get ()    { return this.isOpenSidebarProperty },
      set (val) { this.$store.commit('SET_IS_OPEN_SIDEBAR_PROPERTY', val) }
    },
  },
  methods: {
    ...mapActions(["updateProperty", "deleteProperty", "isArchivingProperty", "archiveProperty", "fetchProperty", "fetchPropertyArchived","restoreProperty"]),

    getPropertyArchivedDetails(id, archived) {
      this.archived=archived
      if (id>0) {
        this.fetchPropertyArchived(id).then(res => {
          this.property = res
          this.property.jobId = res.job.id
          this.property.contractId = res.contract.id
        })
      } else {
        this.property={}
      }
    },
    getPropertyDetails(id) {
      this.archived=false
      if (id>0) {
        this.fetchProperty(id).then(res => {
          this.property = res
          this.property.jobId = res.job.id
          this.property.contractId = res.contract.id
        })
      } else {
        this.property={}
      }
    },

    editProperty(id){
        this.$store.commit('SET_IS_OPEN_SIDEBAR_PROPERTY', false)
        this.$router.push({ name: 'edit-property', params: { id: id, title: "Edit : "+ this.property.firstName + ' ' + this.property.lastName, tips:"Editer le collaborateur : "+this.property.firstName + ' ' + this.property.lastName } })
    },
    
    archivePropertyLocal (id, label) {
      this.$bvModal
        .msgBoxConfirm('Vous pourrez à tout moment restaurer cette fiche en consultant la liste de vos archives.', {
          title:
            'Êtes-vous sûr de vouloir archiver "' + label + '" ?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Archiver',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.archiveProperty(id);
            this.$store.commit('SET_IS_OPEN_SIDEBAR_PROPERTY', false)
          }
        })
    },
    restorePropertyLocal (id, label) {
      this.$bvModal
        .msgBoxConfirm('Vous pourez ré-utiliser cette fiche dans tous les modules de votre application.', {
          title:
            'Êtes-vous sûr de vouloir restaurer "' + label + '" ?',
          size: 'sm',
          okVariant: 'warning',
          okTitle: 'Restaurer',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.restoreProperty(id);
            this.$store.commit('SET_IS_OPEN_SIDEBAR_PROPERTY', false)
          }
        })
    },
    deletePropertyLocal (id, label) {
      this.$bvModal
        .msgBoxConfirm('Cette action est définitive et irréversible.', {
          title:
            'Êtes-vous sûr de vouloir supprimer "' + label + '" ?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Supprimer',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.deleteProperty(id)
            this.$store.commit('SET_IS_OPEN_SIDEBAR_PROPERTY', false)
          }
        })
    },
  },
  components: {
    VuePerfectScrollbar
  }
}
</script>

